/** 
 * OfficeGest
 * ©2021 Guisoft
 *
 * Ace Admin Template v2.1
 * Copyright 2013-2020
 *
 * ATENÇÃO! Este ficheiro é o resultado de uma compilação, não deve ser editado diretamente.
 **/

import Util from './src/util'
import Basic from './src/general'
import Scrollbar from './src/scrollbar'
/*import Sidebar from './src/sidebar'*/
import Aside from './src/aside'
import Toaster from './src/toaster'
import Widget from './src/widget'
import FileInput from './src/fileinput'
import Wysiwyg from './src/wysiwyg'
import './src/extra'

export default {
  Util,
  Basic,
  Scrollbar,
  /*Sidebar,*/
  Aside,
  Toaster,
  Widget,
  FileInput,
  Wysiwyg
}
